const Header = () => import('@/views/Layouts/Store');
export default {
	path: '/product-details',
	name: 'OtherProducts',
	redirect: '/product-details',
	component: Header,
	children: [
		{
			path: '/product-details/:productName?',
			name: 'ProductDetails',
			component: () => import('@/views/ProductDetails'),
			meta: {
				auth: undefined,
			},
		},
	],
};
